import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import Layout from "../components/Layout/layout"
import Image from "gatsby-image"
import {
  ButtonBase,
  Card,
  CardActionArea,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"

const Products = ({ classes, data }) => {
  const {
    mdx: {
      frontmatter: { category, title, date, slug, tagline },
      id,
      body,
    },
  } = data

  const productListing = data.mdx.exports.productListing
  return (
    <Layout>
      <Container className={classes.container}>
        <Typography className={classes.pageHeader} gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={4}>
          {productListing.map(listing => {
            const productImage = listing.img.childImageSharp.fluid
            return (
              <Fragment key={listing.id}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Card square className={classes.card}>
                    <CardActionArea component={Link} to={listing.href}>
                      <Image
                        fluid={productImage}
                        alt="Custom embroidered patches"
                        className={classes.img}
                      />
                    </CardActionArea>
                  </Card>
                  <ButtonBase
                    component={Link}
                    to={listing.href}
                    className={classes.commonButton}
                  >
                    {listing.name}
                  </ButtonBase>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  container: {
    marginTop: 25,
  },
  pageHeader: {
    fontSize: "48px",
    fontWeight: "light",
    letterSpacing: "3px",
    textTransform: "uppercase",
    lineHeight: "97%",
  },
  img: {
    transform: " scale(1)",
    transition: "transform .2s",
    "&:hover": {
      transform: " scale(1.2)",
      transition: "transform .2s",
    },
  },
})

export const query = graphql`
  query GetProducts($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        tagline
        image01 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      body
      exports {
        productListing {
          id
          name
          href
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
export default withStyles(styles)(Products)
